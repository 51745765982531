import { Component, Input, OnDestroy, OnInit, ViewEncapsulation, ViewChild} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomersService } from '../../../main/pages/customers/customers-service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { CustomerBalanceAdjustmentComponent } from 'app/main/pages/customers/customer-balance-adjustment/customer-balance-adjustment.component';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/shared/auth.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { CustomerMaxLimitComponent } from 'app/main/pages/customers/customer-maxlimit/customer-maxlimit.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { PayoutReceiptComponent } from 'app/main/pages/reports/payout-receipt/payout-receipt.component';
import { ResendTriggerComponent } from './resend-trigger/resend-trigger.component';
import { isLiveEnv } from 'app/constants';

@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class QuickPanelComponent implements OnInit, OnDestroy
{
    customerDetails;
    displayedColumns = ['lastupdated', 'amount', 'updatedBalance', 'redeemType', 'referenceNo', 'transactionID','sourse', 'comment','secondaryPointAccountID','createdBy'];
    displayedColumns2 = ['HoldAmount', 'CreatedDate', 'ExpiryDate'];
    redeemTypes: any = [];
    PointAccounts;
    date: Date;
    events: any[];
    notes: any[];
    settings: any;
    searchForm: FormGroup;
    public currentdate = new Date();
    public currentyear : number = this.currentdate.getFullYear();
    PageSize: number = 10;
    pageNumber: number = 1;
    dataSource = new MatTableDataSource([]);
    @ViewChild(MatPaginator, {static: false }) paginator: MatPaginator;
    isBlockText:any='';  
    selectedPointID: any;
    PointAccountIds: any = [];
    PointAccountIdForm: FormGroup;
  maxdate = new Date(this.currentyear, 12,0)
  minDate = new Date(2000, 0, 1);

    // Private
    private _unsubscribeAll: Subject<any>;
    userDetails: any;
    writePermissions: boolean = false;
    impersonation: boolean = false;
    manualAdjustment: boolean = false;
    isTgyaAccountExist:boolean = false;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * 
     */
    constructor(
        private _httpClient: HttpClient,    
        public CustomersService: CustomersService,
        private _formBuilder: FormBuilder,
        private _matDialog: MatDialog,
        private _toastr: ToastrService,
        public _AuthService: AuthService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseSidebarService: FuseSidebarService,
    )
    {
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud : false,
            retro : true
        };
        this.searchForm = this._formBuilder.group({
            searchByFromDate: [moment().add(-30,"days")],
            searchByToDate: [moment()]
          });
          this.PointAccountIdForm = this._formBuilder.group({
            SelectedPointAccountID:[this.selectedPointID],
          });
          this.PointAccountIdForm.get('SelectedPointAccountID').valueChanges.subscribe(value => {
            this.pointAccountIdChange(value)
          })
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.userDetails = JSON.parse(this._AuthService.getUserData());
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to the events
        this._httpClient.get('api/quick-panel-events')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                this.events = response;
            });

        // Subscribe to the notes
        this._httpClient.get('api/quick-panel-notes')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((response: any) => {
                this.notes = response;
            });

        let that = this;
        this._fuseSidebarService.getSidebar('quickPanel').openedChanged.subscribe(function(isOpened){
            if(isOpened) {
              that.writePermissions = false;
              that.impersonation = false;
              that.manualAdjustment = false;
              that.userDetails = JSON.parse(that._AuthService.getUserData());
              that.searchForm.get('searchByFromDate').setValue(moment().add(-30,"days"));
              that.searchForm.get('searchByToDate').setValue(new Date());
              that.getRedeemTypeList();
              if (that.userDetails.role != 3) {
                that.writePermissions = true;
                that.impersonation = true;
                that.manualAdjustment = true;
              } else {
                that.userDetails.writePermissions.forEach((obj) => {
                    if (obj == "customers") {
                        that.writePermissions = true;
                    }
                });
                that.userDetails.permissions.forEach((obj) => {
                  if (obj == "impersonation") {
                    that.impersonation = true;
                  }

                  if (obj == "manual-adjustment") {
                    that.manualAdjustment = true;
                  }
              });
              }
            }
        });
    }
    resetsearchForm() {
        //this.searchForm.get('pointAccount').setValue(this.PointAccounts[0].id);
        this.searchForm.get('searchByFromDate').setValue(moment().add(-30,"days"));
        this.searchForm.get('searchByToDate').setValue(new Date());
        this.getAllPointTransactions();
      }
    pointAccountIdChange(selectedPointId)
    {
      this.selectedPointID = selectedPointId;
      this.getAllPointTransactions();
    }
    getAllPointTransactions() {
        this._fuseSplashScreenService.show();
        this.customerDetails = JSON.parse(localStorage.getItem('customer'));
        const request = {
            CustomerID: this.CustomersService.selectedCustomerid,
            PointAccountID: this.PointAccountIds.length > 0 ?  this.selectedPointID : localStorage.getItem("pointAccountIDValue"),
            CompanyID: this.CustomersService.selectedCompanyid,
            SortBy: "lastupdated",
            SortDirection: "Desc",
            StartDate: this.searchForm.get('searchByFromDate').value ? moment(this.searchForm.get('searchByFromDate').value).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'),
            EndDate: this.searchForm.get('searchByToDate').value ? moment(this.searchForm.get('searchByToDate').value).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')
        }
        this.CustomersService.GetAllPointAccountTransactons(request).subscribe((res: any) => {
            this._fuseSplashScreenService.hide();
            this.CustomersService.customerTransactionDetails = res.data;
            this.CustomersService.customerDetails = res.data.customerDetails;
            this.CustomersService.customerPointBalancesDetails = res.data.customerPointBalancesDetails;
            this.CustomersService.accountTotalCount = res.data.transactionsDetails.length;
            this.CustomersService.holdTotalCount = res.data.holdTransactionDetails.length;
            this.CustomersService.holdTransactionDetails = new MatTableDataSource(res.data.holdTransactionDetails);
            this.CustomersService.AccountTransactionList = new MatTableDataSource(res.data.transactionsDetails);
            this.CustomersService.AccountTransactionList.paginator = this.paginator;

            this.checkIsCustomerBlock();
            this.checkTygaAccount();
        }, error => {
          this._fuseSplashScreenService.hide();
          this._toastr.error(error, 'Error');
        });
    }

      adjustBalance(customer: any = null) {
        this.customerDetails = JSON.parse(localStorage.getItem('customer'));
        const dialogRef = this._matDialog.open(CustomerBalanceAdjustmentComponent, {
          width: '650px',
          panelClass: 'adjust-balance',
          data: {
            action: customer ? 'edit' : 'new',
            data: this.customerDetails
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'Success') {
            this.getAllPointTransactions();
          }
        });
      }


    getPointAccounts() {
      const request = '?companyId=' + this.CustomersService.selectedCompanyid;
      this._fuseSplashScreenService.show();
      this.CustomersService.GetCompanyPointDetail(request).subscribe((res: any) => {
          if (res.data) {
            this._fuseSplashScreenService.hide();
            this.PointAccountIds = res.data;
            var PointAccountIds:any = localStorage.getItem("pointAccountIDValue");
            this.selectedPointID = this.PointAccountIds.some(e => e.id == PointAccountIds) ?  PointAccountIds :  this.PointAccountIds[0].id ;
            this.PointAccountIdForm.controls['SelectedPointAccountID'].setValue(this.selectedPointID);
          } else {
              this._fuseSplashScreenService.hide();
              this._toastr.error('Error');
          }
      }, error => {
          this._fuseSplashScreenService.hide();
          this._toastr.error(error, 'Error');
      });
    }

    setMaxLimit(customer: any = null) {
      this.customerDetails = JSON.parse(localStorage.getItem('customer'));
      const dialogRef = this._matDialog.open(CustomerMaxLimitComponent, {
        width: '650px',
        panelClass: 'adjust-balance',
        data: {
          action: customer ? 'edit' : 'new',
          data: this.customerDetails
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'Success') {
          this.getAllPointTransactions();
        }
      });
    }
    toggleBlock() {
      if (this.isBlockText == "Disable") {
        this.blockCustomer();
      }else{
        this.unBlockCustomer();
      }
    }

  blockCustomer() {
    //this._fuseSplashScreenService.show();
    var confirmDialogRef = this._matDialog.open(
      FuseConfirmDialogComponent,
      {
        disableClose: false,
        panelClass: "confirm-dialog",
      }
    );
    confirmDialogRef.componentInstance.confirmMessage =
      "Are you sure you want to Block User?";
    confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const request = {
          externalCustomerId: this.CustomersService.customerDetails.externalCustomerID,
          companyID: this.CustomersService.selectedCompanyid,
          pointAccountID:  this.PointAccountIds.length > 0 ?  this.selectedPointID : localStorage.getItem("pointAccountIDValue")
        }
        this.CustomersService.BlockCustomer(request).subscribe((res: any) => {
          this._fuseSplashScreenService.hide();
          if (res && res.data) {
            this._fuseSplashScreenService.hide();
            this.isBlockText = "Enable";
            this._toastr.success('Customer blocked!', 'Success');
          } else {
            this._fuseSplashScreenService.hide();
            this._toastr.error(res.errorDescription, 'Error');
          }

        }, error => {
          this._fuseSplashScreenService.hide();
          this._toastr.error(error, 'Error');
        });
      }
    });
  }
  unBlockCustomer() {
    //this._fuseSplashScreenService.show();
    var confirmDialogRef = this._matDialog.open(
      FuseConfirmDialogComponent,
      {
        disableClose: false,
        panelClass: "confirm-dialog",
      }
    );
    confirmDialogRef.componentInstance.confirmMessage =
      "Are you sure you want to UnBlock User?";
    confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const request = {
          externalCustomerId: this.CustomersService.customerDetails.externalCustomerID,
          companyID: this.CustomersService.selectedCompanyid
        }
        this.CustomersService.UnBlockCustomer(request).subscribe((res: any) => {
          this._fuseSplashScreenService.hide();
          if (res && res.data) {
            this._fuseSplashScreenService.hide();
            this.isBlockText = "Disable";
            this._toastr.success('Customer unblocked!', 'Success');
          } else {
            this._fuseSplashScreenService.hide();
            this._toastr.error(res.errorDescription, 'Error');
          }

        }, error => {
          this._fuseSplashScreenService.hide();
          this._toastr.error(error, 'Error');
        });
      }
    });
  }
    checkIsCustomerBlock() {
      this._fuseSplashScreenService.show();
      const request = {
        externalCustomerId: this.CustomersService.customerDetails.externalCustomerID,
        companyID: this.CustomersService.selectedCompanyid,
        pointAccountID: this.PointAccountIds.length > 0 ?  this.selectedPointID : localStorage.getItem("pointAccountIDValue")
      }
      this.CustomersService.IsCustomerBlock(request).subscribe((res: any) => {
          this._fuseSplashScreenService.hide();
          if(res){
            this.isBlockText= "Enable";
          }else{
            this.isBlockText= "Disable";
          }

      }, error => {
        this._fuseSplashScreenService.hide();
        this._toastr.error(error, 'Error');
      });
    }
    openPayoutRecipe(transaction){

    const dialogRef = this._matDialog.open(PayoutReceiptComponent, {
      panelClass: "payout-receipt-details",
          disableClose: true,
          data: {
          transactionID: transaction.id,
      },
        });
      dialogRef.afterClosed().subscribe((response) => {
            if (!response) {
              return;
            }
            this.getAllPointTransactions();
          });
  }
  getRedeemTypeList(){
    this.CustomersService.GetRedeemType().subscribe((res: any) => {
      this._fuseSplashScreenService.hide();
      if(res){
        this.redeemTypes = res;
        this.getPointAccounts()
      }

  }, error => {
    this._fuseSplashScreenService.hide();
    this._toastr.error(error, 'Error');
  });
  }
  getRedeemType(redeemType){
    return this.redeemTypes.find(val => val.value == redeemType).name;
  }
  getPointAccName(redeemType){
    if(redeemType){
      return this.PointAccountIds.find(val => val.id == redeemType).accountName;
    }
    return redeemType;
  }
  syncCustomer() {
    this._fuseSplashScreenService.show();
    var req = {
      "externalCustomerID":this.CustomersService.customerDetails.externalCustomerID,
      "companyId":this.CustomersService.customerDetails.companyID
    }
    this.CustomersService.syncCustomer(req).subscribe((res: any) => {
      this._fuseSplashScreenService.hide();        
      if(res.data == "true" || res.data){
        this._toastr.success("Customer synced successfully!", 'Success');
        this.getAllPointTransactions();
      }else{
        this._toastr.error(res.message, 'Error');
      }
    }, error => {
      this._fuseSplashScreenService.hide();
      this._toastr.error(error, 'Error');
    });
  }
  impersonate() {
    this._fuseSplashScreenService.show();
    var req = {
      "backofficeID":this.CustomersService.customerDetails.backofficeID,
      "companyId":this.CustomersService.customerDetails.companyID
    }
    this.CustomersService.ImpersonateToCustomerPortal(req).subscribe((res: any) => {
      this._fuseSplashScreenService.hide();
      if(res){
        var companyKey = "";
        if(this.userDetails.role == 1){
          this.customerDetails = JSON.parse(localStorage.getItem('customer'));
          companyKey = this.customerDetails.companyKey;
        }else{
          companyKey = this.userDetails.companyKey;
        }
        var url = isLiveEnv ? `https://${companyKey}.paymenture.com/login/${res}` : `https://${companyKey}.stage.paymenture.com/login/${res}`;
        window.open(url, "_blank");
      }
    }, error => {
      this._fuseSplashScreenService.hide();
      this._toastr.error(error, 'Error');
    });
  }
  checkTygaAccount() {
    this._fuseSplashScreenService.show();
    var req = {
      "externalCustomerID":this.CustomersService.customerDetails.externalCustomerID,
      "companyId":this.CustomersService.customerDetails.companyID
    }
    this.CustomersService.checkTygaAccount(req).subscribe((res: any) => {
      this._fuseSplashScreenService.hide();        
      if(res){
        this.isTgyaAccountExist = res;
      }else if(res == false){
        this.isTgyaAccountExist = res;
      }else{
        this._toastr.error(res, 'Error');
      }
    }, error => {
      this._fuseSplashScreenService.hide();
      this._toastr.error(error, 'Error');
    });
  }
  openTygaModal(){
    var confirmDialogRef = this._matDialog.open(
      FuseConfirmDialogComponent,
      {
        disableClose: false,
        panelClass: "confirm-dialog",
      }
    );
    confirmDialogRef.componentInstance.confirmMessage =
      "Are you sure you want to Unlink Tyga Account?";
    confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._fuseSplashScreenService.show();
        const request = {
          externalCustomerId: this.CustomersService.customerDetails.externalCustomerID,
          companyID: this.CustomersService.selectedCompanyid
        }
        this.CustomersService.removeTygaAccount(request).subscribe((res: any) => {
          this._fuseSplashScreenService.hide();
          if (res && res.data == true) {
            this._toastr.success("Success", "Account successfully unlinked!");
             this.checkTygaAccount();
          } else {
            this._fuseSplashScreenService.hide();
            this._toastr.error(res.message, 'Error');
          }

        }, error => {
          this._fuseSplashScreenService.hide();
          this._toastr.error(error, 'Error');
        });
      }
    });
  }
  ExportCustomerAllPointTransactions() {
    this._fuseSplashScreenService.show();
    this.customerDetails = JSON.parse(localStorage.getItem('customer'));
    const request = {
        CustomerID: this.CustomersService.selectedCustomerid,
        PointAccountID: this.PointAccountIds.length > 0 ?  this.selectedPointID : localStorage.getItem("pointAccountIDValue"),
        CompanyID: this.CustomersService.selectedCompanyid,
        SortBy: "lastupdated",
        SortDirection: "Desc",
        StartDate: this.searchForm.get('searchByFromDate').value ? moment(this.searchForm.get('searchByFromDate').value).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'),
        EndDate: this.searchForm.get('searchByToDate').value ? moment(this.searchForm.get('searchByToDate').value).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY')
    }
    this.CustomersService.ExportCustomerAllPointTransactions(request).subscribe((res: any) => {
        this._fuseSplashScreenService.hide();
        var blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
        this.CustomersService.SaveFile(blob, "CustomerAllPointTransactions");
    }, error => {
      this._fuseSplashScreenService.hide();
      this._toastr.error(error, 'Error');
    });
}
  resendTrigger(){
    var confirmDialogRef = this._matDialog.open(
      ResendTriggerComponent,
      {
        width: '500px',
        disableClose: false,
        panelClass: "confirm-dialog",
      }
    );
  }
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
